<template>
  <div class="container-web-app">
    <ArrowBack :path-name="toPageUrl($options.name)" />
    <br />
    <v-subheader class="headline text-center font-weight-bold d-block mb-6">
      <span
        :class="{
          'header--light': !isDarkModeEnabled,
          'header--dark': isDarkModeEnabled
        }"
      >
        Twitch Channel Points Bonus Collector
      </span>
      Privacy Policy
    </v-subheader>

    <p>
      The user data that is generated by the extension, for each browser, is stored locally only and is not forwarded to
      any 3rd party server.
    </p>

    <div class="mt-4">
      The extension is available on
      <span v-for="(platform, i) of platforms" :key="platform.url">
        <Url :dark-mode="isDarkModeEnabled" :href="platform.url">{{ platform.label }}</Url>
        <span v-if="i < platforms.length - 1">, </span>
        <span v-if="i === platforms.length - 2">and </span></span
      >.
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ArrowBack from "@/components/ArrowBack";
import Url from "@/components/Url";

export default {
  name: "TwitchCpBonusCollector",
  components: { Url, ArrowBack },
  data() {
    return {
      platforms: []
    };
  },
  computed: {
    ...mapState(["products"]),
    ...mapGetters(["toPageUrl", "getOrderedServices"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.$options.name);
    }
  },
  methods: {
    getPlatforms() {
      const ythd = this.products.find(({ pageUrl }) => this.toPageUrl(this.$options.name) === pageUrl);

      const platforms = ythd.platforms.slice(1);
      return this.getOrderedServices(platforms);
    }
  },
  async created() {
    this.platforms = this.getPlatforms();
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.header--light {
  color: #9248ff;
}

/*noinspection CssUnusedSymbol*/
.header--dark {
  color: #9248ff;
}
</style>
